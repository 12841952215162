.content-img {
    margin-top: 1.5rem;

    background: linear-gradient(rgba(255, 255, 255, 0.335), rgba(255, 255, 255, 0.26)),
        url('../../images/bg.png');
    background-size: cover;
    min-height: 50vh;
    min-width: 100%;

    h1 {
        padding-top: 8rem;
    }

    h2 {
        padding-top: 1rem;
    }

    .button {
        background-color: #4472C4;
        color: white;
        font-weight: 500;
        font-size: 1.3em;
        width: 8rem;
        border: none;
        padding: 0.2rem;
        border-radius: 6px 6px 6px 6px;
        margin-top: 1.3rem;

        &:hover {
            transform: scale(1.2);
        }
    }
}

/* Smartphones  ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {

    .content-img {
        background: none;

        h1 {
            font-size: 1.9em;
            padding-top: 2rem;
        }

        h2 {
            font-size: 1.2em;
            padding-top: 0.5rem;
            width: 100%;
        }

        .button {
            margin-top: 0.5rem;

        }
    }

    .button {
        margin-bottom: 10rem;
    }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .content-img {

        h1 {
            padding-top: 10rem;
        }
    }
}