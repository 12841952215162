.container-fluid {
    padding: 0;



    h1#services.text-center {
        margin-top: 1rem !important;
        margin-bottom: 0 !important;

        &:after {

            display: block;
            content: '';
            width: 10%;
            height: 2px;
            background: black;
            position: absolute;
            bottom: 32%;
            left: 45%;
            background-color: #4472C4;


        }
    }

    .padding {
        margin-top: 3rem;
        // box-shadow: 0px 0px 5px 2px rgba(163, 163, 163, 1);
    }

    .item {
        padding: 3rem;
        // box-shadow: 0px 0px 5px 2px rgba(163, 163, 163, 1);
    }

    i {
        font-size: 2.4em;
        color: #4472C4;
    }

    h4 {
        font-size: 1.4em;
        margin-top: 1rem !important;
        margin-bottom: 1rem !important;
    }

    p.offer {
        margin-top: 2rem !important;
        margin-bottom: 0;


    }


}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .container-fluid {

        #header-img {
            background: none;
            display: none;
            // margin-top: -30rem;
        }

        h1#services.text-center {
            margin-top: 2rem;

            &:after {

                top: 54%;
                left: 40%;
                width: 20%;

            }
        }

        .padding {
            margin-top: 1rem;

        }

        .item {
            padding: 1rem;

        }

    }
}