.con {
    margin-top: 2.2rem;
    margin-bottom: -10rem;
    background: linear-gradient(rgba(255, 255, 255, 0.226), rgba(255, 255, 255, 0.15)),
        url('../../images/banner.png');
    background-size: cover;
    min-height: 300px;
    min-width: 100%;

    h2 {
        padding-top: 6.5rem;
        color: white;
    }

    .button {
        background-color: #4472C4;
        color: white;
        font-weight: 500;
        font-size: 1.2em;
        width: 8rem;
        border: none;
        padding: 0.2rem;
        border-radius: 6px 6px 6px 6px;
        margin-top: 1.3rem;

        &:hover {
            transform: scale(1.2);
        }
    }

}

/* Smartphones  ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .con {
        background: none;
        margin-top: 0rem;
        margin-bottom: -13rem;
    }

    h2 {
        font-size: 1.3em;
        color: black !important;
        padding-top: 1rem !important;
    }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .con {
        margin-bottom: 8rem;
        margin-top: -4rem;
    }
}