.container-fluid {
    margin-bottom: -6rem;



    main.main-contact {
        margin-top: -4rem;


        h1#contact {
            margin-bottom: 5rem !important;

            &:after {

                display: block;
                content: '';
                width: 5%;
                height: 2px;
                background: black;
                position: absolute;
                bottom: 35%;
                left: 47.3%;
                background-color: #4472C4;


            }
        }

        .container {
            background-color: white;
            margin-top: 5rem;

            h2 {
                margin-bottom: 4rem !important;
                color: #666666;
            }


            form {
                color: #707070;
            }

            .button {
                width: 65px;
                height: 30px;
                border-radius: 21px;
                border: solid 1px #707070;
                background-color: #4472C4;
                display: block;
                text-align: center;
                text-transform: uppercase;
                color: white;
                line-height: 1.7rem;
                cursor: pointer;
                user-select: none;

                &:hover {
                    transform: scale(1.1);
                }

            }

            li {
                i {

                    color: #4472C4;
                    cursor: pointer;
                    font-size: 2em;

                }
            }

            .envelope {
                display: inline-block;

                &:hover .fa-envelope::before {
                    content: "\f2b6";

                }
            }

            .phone {
                display: inline-block;

                &:hover .fa-phone::before {
                    content: "\f879";
                }
            }

        }
    }

}


@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {

    .container-fluid {
        margin-bottom: -4rem;




        main.main-contact {
            margin-top: -3rem;

            h1#contact {
                margin-bottom: 3rem !important;

                &::after {
                    bottom: 71.5%;
                    left: 40%;
                    width: 20%;
                }
            }

            #form-col {
                margin-bottom: -9rem !important;
            }

        }
    }

    li {
        margin-top: 1rem;
    }

}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {

    main.main-contact {
        margin-bottom: 10rem;

        h1#contact {
            &::after {
                bottom: 42% !important;
                left: 44% !important;
                width: 12% !important;
            }
        }

    }

}