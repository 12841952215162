#con {
    margin-top: 10rem;
    display: grid;
    grid-template-areas:
        ". box1 box2 .";
    grid-template-columns: 0.5fr 2fr 1fr 0.5fr;


    h3 {
        position: relative;

        &:after {

            display: block;
            content: '';
            width: 10%;
            height: 2px;
            background: black;
            position: absolute;
            bottom: -65%;
            left: 35%;
            background-color: #4472C4;

        }

    }

    .box1 {
        grid-area: box1;
    }

    .box2 {
        grid-area: box2;
    }

    .align-center {
        text-align: center;
        margin-top: 3rem;
        font-size: 1.2em;
        font-weight: 500;
    }

    .align-left {
        display: inline-block;
        text-align: left;
    }


}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {

    #con {
        margin-top: -3rem;
        display: block;
        margin-bottom: -2rem;


        h3 {
            font-size: 1.2em;

            &:after {
                display: none;
            }
        }

        .align-center {
            margin-top: 1.3rem;
        }
    }

    .animated {
        animation: none;

    }


}