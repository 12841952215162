#icons {

    margin-top: 6rem;
    margin-bottom: 1rem;

    i {
        color: #4472C4;
        font-size: 4em;
        background: #fffefe;
        border-radius: 50%;
        border: 2px solid black;


        &:hover {
            transform: scale(1.1);
            color: black;
        }
    }

    .fa-book {
        padding: 25px 35px;
    }

    .fa-chart-line {
        padding: 25px 30px;

    }

    .fa-users {
        padding: 25px 24px;
    }

    .fa-chart-pie {
        padding: 25px 25px;
    }

    h3 {
        font-size: 1.2em;
        font-weight: 700;
        margin-top: 1rem;
    }
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {

    #icons {

        margin-top: -8rem;
        margin-bottom: 5rem;

        i {
            margin-top: 3rem;
        }

    }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {

    #icons {
        margin-bottom: 4rem;
    }

    div#con.container-fluid {
        margin: 0;
    }

    footer {

        margin: 0 !important;
        margin-top: 2rem !important;

    }


}