header {
    margin-bottom: 1rem;






    .logo {
        padding: 1rem;
        display: block;


    }

    nav {
        margin-top: -6rem;

        // margin-right: 20rem !important;

        a {
            font-size: 1.2em;
            padding-left: 2rem !important;
            font-weight: 500;
            color: rgb(78, 78, 78) !important;


            &:hover {
                text-decoration: underline !important;
            }
        }

        .active {
            color: #4472C4 !important;
        }


    }
}

/* Smartphones  ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    header {

        .logo {
            // width: 60%;
            padding: 0;
            margin-right: 3rem;
        }
    }

    nav {
        margin-top: -4rem !important;
    }


}