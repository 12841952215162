footer {
    margin-top: 13rem;
    color: #4472C4;
    font-size: 0.8em;

    span {
        font-weight: 500;
    }

    h5 {
        font-size: 1.4em;
    }

    a {
        color: #6a9df5 !important;

    }
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {

    footer {
        margin-top: 5rem;
        font-size: 0.8em;
    }
}

/*==================== 
	Footer 
====================== */

/* Main Footer */
footer .main-footer {
    padding: 20px 0;
    background: #252525e0;

}

footer ul {
    padding-left: 0;
    list-style: none;


}

/* Copy Right Footer */
.footer-copyright {
    background: #222;

    padding: 5px 0;
}

.footer-copyright .logo {
    display: inherit;
}



.footer-copyright nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.footer-copyright nav ul li {
    border-left: 1px solid #505050;
    display: inline-block;
    line-height: 12px;
    margin: 0;
    padding: 0 8px;
}

.footer-copyright nav ul li a {
    color: #969696;
}

.footer-copyright nav ul li:first-child {
    border: medium none;
    padding-left: 0;
}

.footer-copyright p {
    color: #969696;
    margin: 2px 0 0;
}

/* Footer Top */
.footer-top {
    background: #252525;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 3px solid #222;
}

/* Footer transparent */
footer.transparent .footer-top,
footer.transparent .main-footer {
    background: transparent;
}

footer.transparent .footer-copyright {
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0.3);
}

/* Footer light */
footer.light .footer-top {
    background: #f9f9f9;
}

footer.light .main-footer {
    background: #f9f9f9;
}

footer.light .footer-copyright {
    background: none repeat scroll 0 0 rgba(255, 255, 255, 0.3);
}

/* Footer 4 */
.footer- .logo {
    display: inline-block;
}

/*==================== 
	Widgets 
====================== */
.widget {
    padding: 20px;
    margin-bottom: 40px;
}

.widget.widget-last {
    margin-bottom: 0px;
}

.widget.no-box {
    padding: 0;
    background-color: transparent;
    margin-bottom: 40px;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    -o-box-shadow: none;
}

.widget.subscribe p {
    margin-bottom: 18px;
}

.widget li a {
    color: #6a9df5;
}

.widget li a:hover {
    color: #4b92dc;
}

.widget-title {
    margin-bottom: 20px;
}

.widget-title span {
    background: #4472C4 none repeat scroll 0 0;
    display: block;
    height: 1px;
    margin-top: 25px;
    position: relative;
    width: 20%;
}

.widget-title span::after {
    background: inherit;
    content: "";
    height: inherit;
    position: absolute;
    top: -4px;
    width: 50%;
}

.widget-title.text-center span,
.widget-title.text-center span::after {
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.widget .badge {
    float: right;
    background: #7f7f7f;
}

.typo-light h1,
.typo-light h2,
.typo-light h3,
.typo-light h4,
.typo-light h5,
.typo-light h6,
.typo-light p,
.typo-light div,
.typo-light span,
.typo-light small {
    color: #fff;
}

ul.social-footer2 {
    margin: 0;
    padding: 0;
    width: auto;
}

ul.social-footer2 li {
    display: inline-block;
    padding: 0;
}

ul.social-footer2 li a:hover {
    background-color: #ff8d1e;
}

ul.social-footer2 li a {
    display: block;
    height: 30px;
    width: 30px;
    text-align: center;
}

.btn {
    background-color: #4472C4;
    color: #fff !important;
}

.btn:hover,
.btn:focus,
.btn.active {
    background: #4b92dc;
    color: #fff;
    -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    -webkit-transition: all 250ms ease-in-out 0s;
    -moz-transition: all 250ms ease-in-out 0s;
    -ms-transition: all 250ms ease-in-out 0s;
    -o-transition: all 250ms ease-in-out 0s;
    transition: all 250ms ease-in-out 0s;

}